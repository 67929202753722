import * as React from 'react'

import Page from '../components/Page'
import Container from '../components/Container'
import IndexLayout from '../layouts'
import CartHeader from '../components/cart/CartHeader'
import styled from '@emotion/styled'
import LilaBigCartItems from '../components/cart/BigCartItems'



const LilaContainer = styled(Container)`

`


const LilaCart = styled.div`

`
const Cart = ({pathContext: { locale }}) => (
  <IndexLayout locale={locale}>
    <Page>
      <LilaContainer>
    <CartHeader/>
<LilaCart>
  <LilaBigCartItems/>
</LilaCart>
      </LilaContainer>
    </Page>
  </IndexLayout>
)

export default Cart
