import ContextConsumer from "../Context"
import React from "react"
import styled from '@emotion/styled'
import { colors, fonts } from '../../styles/variables'
import CartThumb from '../products/CartThumb'
import { FormattedMessage } from 'react-intl';
import Ripples from 'react-ripples'
import LocalizedLink from '../links/LocalizedLink'
import Fade from 'react-reveal/Fade';
import StockProvider from '../StockProvider'



const LilaCheckout = styled.div`
margin-top:3rem;
display:none;
width:100%;
justify-content:center;
align-items:center;

 @media screen and (max-width: 934px) {
display:flex;
}
`

const LilaEmptyCart = styled.div`
width:100%;
display:flex;
justify-content:flex-start;
padding-left:10%;
align-items:center;
font-family:${fonts.bcHairLine};
`

const LilaLink = styled(LocalizedLink)`
width:250px;
height:45px;
justify-content:center;
display:flex;
align-items:center;
`

const LilaProducts = styled(Ripples)`
width:100%;
margin-top:1rem;
width:250px;
height:35px;
border-radius:18px;
border:1px solid var(--border);
display:flex;

justify-content:center;
margin-left:10%;
align-items:center;
font-family:${fonts.bcHairLine};
`

const LilaCartCollection = styled.div`
padding-top:10rem;
transition:all .4s ease;
`


const LilaProceedFade = styled(Ripples)`
font-family:${fonts.bcExtraLight};

letter-spacing:1px;
z-index:999999999;

           // border-bottom: 1px solid var(--txt);
  margin-top:1.8rem;
  border-radius:18px
            //border-top: 1px solid ;
background:none;
            width:250px;

            height:35px;
            color:${colors.fontDark};



`
const LilaProceed = styled(Ripples)`



`

const LilaRipples = styled(LocalizedLink)`
background:var(--haase);
border-radius:18px;
letter-spacing:1px;
           // border-bottom: 1px solid var(--txt);
            //border-top: 1px solid ;
background:var(--haase);
            width:250px;

            height:35px;
            color:${colors.fontDark};
display:flex;
justify-content:center;
align-items:center;
`
class LilaBigCartItems extends React.Component {
  constructor(props: any) {
    super(props)
    this.state = {
      selectedID:-1

    }
    this.setID=this.setID.bind(this)
  }

  setID(id){
  this.setState({
    selectedID: id
  })
  }


  setTotal(set, items){
    let val =  0;
    for(let i=0;i<items.length;i++){
      if(items[i].quantity>0){
        val += items[i].amount;
      }
    }
    set({ total: val })
  }



  plusItem(set, data, id) {

    let newCartItems = data.cartItems;
    let newItem = newCartItems[id];
    if (newItem.quantity < 10) {
      newItem.quantity++
      newItem.amount = newItem.price*newItem.quantity;
    } else {
      newItem.isMax = true;
    }
newCartItems[id]=newItem
    console.log(newItem)
    set({ cartItems: newCartItems })
    this.setTotal(set, newCartItems )

  }


  minusItem(set, data, id) {
    let newCartItems = data.cartItems;
    let newItem = newCartItems[id];
    if (newItem.quantity > 1) {
      newItem.quantity--
      newItem.amount = newItem.price*newItem.quantity;

    } else {
      newItem.isMin = true;
    }
newCartItems[id]=newItem
    console.log(newItem)
    set({ cartItems: newCartItems })
    this.setTotal(set, newCartItems )
  }


  removeItem(set, data, id) {
    let newCartItems = data.cartItems;
    let newItem = newCartItems[id];

      newItem.quantity=0
      newItem.amount = 0;


newCartItems[id]=newItem
    console.log(newItem)
    set({ cartItems: newCartItems })
    this.setTotal(set, newCartItems )

  }

  componentDidMount() {

  }

  render() {
    let amt=0;
    return (

      <ContextConsumer>
        {({ data, set }) => (

          <LilaCartCollection >
            <StockProvider cartItems={data.cartItems} set={set}/>
            {

              data.cartItems.map((item) => (

                  item.quantity >=1 &&
                    <CartThumb link={item.productLink} itemID={item.item} minus={() => this.minusItem(set, data, item.id)}
                                            plus={() => this.plusItem(set, data, item.id)}
                                            removeItem={() => this.removeItem(set,data, item.id)}
                                            quantity={item.quantity} price={item.price}
                                            amount={item.amount} title={item.title} subTitle={item.subTitle}/>

              ))}

            {
              data.total ===0 &&
                <div>
                <LilaEmptyCart><FormattedMessage id={'cart_00'}/></LilaEmptyCart>
                  <LilaProducts><LilaLink to={'/cbd-blueten'}><FormattedMessage id={'cart_01'}/></LilaLink></LilaProducts>
                </div>
            }
            {
              data.total !=0 &&
                <div>
                  <LilaCheckout>  <LilaProceedFade  ><Fade bottom spy={this.state.cartOpen}><LilaProceed ><LilaRipples to={'/checkout'} ><FormattedMessage id={'cart_2'}></FormattedMessage></LilaRipples></LilaProceed></Fade></LilaProceedFade></LilaCheckout>

                </div>
            }

            {

            }


          </LilaCartCollection>
        )}
      </ContextConsumer>
    )
  }
}

export default LilaBigCartItems
