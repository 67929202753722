import * as React from 'react'
import styled from '@emotion/styled'

import 'react-slidedown/lib/slidedown.css'
import { FormattedMessage } from 'react-intl';
import Headroom from 'react-headroom'
import { fonts } from '../../styles/variables'
import Overview from './Overview'
import LocalizedLink from '../links/LocalizedLink'

const HeadroomWrapper = styled.div`
position:absolute;
z-index:999;
width:100%;
background:none;


.headroom {
  top: 0px;
  left: 0;
  right: 0;
      margin-top:82px;

  zIndex: 1;

   @media screen and (max-width:960px){
margin-top:52px;  }

}

.headroom--unfixed {
  position: relative;
  transform: translateY(0);
}
.headroom--scrolled {
  transition: transform 200ms ease-in-out;
}
.headroom--unpinned {
  position: fixed;
  transform: translateY(-100%);
}
.headroom--pinned {
  position: fixed;
  transform: translateY(0%);

}
`

const LilaBackground = styled.div`
background:var(--filter);
  padding-left: 10%;
  width:100%;
 @media screen and (max-width: 1560px) {
  padding-left: 10%;
}

 @media screen and (max-width: 934px) {
 padding-left:10%;
}
`


const LilaShopNav = styled.div`
width:100%;
  margin-bottom:2rem;
z-index:9999999;
background:var(--bg);
transition:all .4s ease;

   @media screen and (max-width:960px){
margin-bottom:7px;  }


font-family:${fonts.bcHairLine};


a{
color:var(--txt) !important;
}




`




const ShopNav = styled.div`
width:100%;
display:flex;
height:50px;
align-items:center;
font-size:1rem;

`





const Selected = styled.div`
margin-right:1rem;
font-family:${fonts.bcExtraLight};
text-decoration:underline;
border-left:1px solid rgba(255,255,255,0.0);


`

const LilaEntry = styled.div`
margin-left:1rem;
margin-right:1rem;
font-family:${fonts.bcHairLine};


`

const LilaOverviewWrap = styled.div`
 @media screen and (max-width: 1400px) {
right:3rem;
}

 @media screen and (max-width: 934px) {
display:none;
}
`


class CartHeader extends React.Component {
  constructor(props: any) {
    super(props)
    this.state = {
filterOpen:false
    }
    this.toggleFilter=this.toggleFilter.bind(this)
  }


  toggleFilter(){
    this.setState({
      filterOpen:!this.state.filterOpen
    })
  }

  componentDidMount() {

  }

  render() {
    return(
<div>
      <HeadroomWrapper>
        <Headroom pinStart={0}>
        <LilaShopNav id={'component'}>
<LilaBackground>
           <ShopNav>
          <Selected><LocalizedLink to={'/cart'}><FormattedMessage id={'cart_x'}/></LocalizedLink></Selected>       >   <LilaEntry><LocalizedLink to={'/checkout'}>checkout</LocalizedLink></LilaEntry>
          </ShopNav>


</LilaBackground>

    </LilaShopNav>
        </Headroom></HeadroomWrapper>
  <LilaOverviewWrap>
    <Overview checkout={true}/>
  </LilaOverviewWrap>
  </div>

    )
  }
}


export default CartHeader


//<P><Zoom delay={4200} right cascade>CBD BLÜTEN</Zoom></P>
