import * as React from 'react'
import styled from '@emotion/styled'

import Ripples from 'react-ripples'
import Fade from 'react-reveal/Fade';
import { fonts } from '../../styles/variables'


import LocalizedLink from '../links/LocalizedLink'
import MaskImg1 from './masks/MaskImg1'
import ShImg1 from './sh/ShImg1'
import AhImg2 from './alien-haze/AhImg2'
import ZkittlezImg2 from './zkittlez/ZkittlezImg2'
import ZkittlezImg3 from './zkittlez/ZkittlezImg3'
import AhImg3 from './alien-haze/AhImg3'

const LilaHover = styled.div`
height:0%;
border-left:2px solid var(--hase);
transition:all .5s ease;





`

const LilaWrap = styled.div`
display:flex;
position:relative;
z-index:99999;

 @media screen and (max-width: 1500px) {
width:160px;
}


 @media screen and (max-width: 934px) {
width:33%;}

`
const LilaHoverWrap = styled.div`
display:flex;
height:25%;
position:absolute;
left:-10%;
top:0%;





 @media screen and (max-width: 1200px) {
top:10%;
}


 @media screen and (max-width: 934px) {
top:0%}

 @media screen and (max-width: 734px) {
top:10%;}

align-items:center;
justify-content:center;
`


const LilaCartThumb = styled.div`
width:100%;
transition:all .4s ease;
display:flex;
margin-right:10%;
padding-left:10%;
position:relative;
 @media screen and (max-width: 934px) {
width:100%;
}

 @media screen and (max-width: 1560px) {
}


:hover{

${LilaHover}{
height:63.2px;}
}



`

const LilaProductInfo = styled.div`
width:100%;
display:flex;
align-items:center;
padding-left:18px;


`

const Img = styled.div`
width:180px;
`

const LilaRemove = styled(Ripples)`
cursor:pointer;
width:40px;
height:40px;
border-radius:50%;
display:flex;
justify-content:center;
height:40px;
align-items:center;
font-size:0.9rem;
margin-left:7px;

.close-icon {
display:flex;
align-items:center;
  cursor: pointer;
  height: 1px;
  position: relative;
  margin-right:10px;
  &:before,
  &:after{
    background: var(--txt);
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    top: 0;
    width: 10px;
    transition: transform 500ms ease;
  }
  &:after {
    transform-origin: center;
  }
  &.collapsed {
    &:after {
      transform: rotate(135deg);
    }
    &:before {
      transform: rotate(45deg);
    }
  }
}

`

const LilaItemCol = styled.div`
width:240px;
display:flex;


 @media screen and (max-width: 1200px) {
width:140px}

 @media screen and (max-width: 934px) {
width:33%}
`

const LilaItemCol_2 = styled.div`
width:240px;

 @media screen and (max-width: 376px) {
justify-content:flex-end;}

 @media screen and (max-width: 1200px) {
width:140px}

 @media screen and (max-width: 934px) {
width:33%}
`


const LilaItemTitle = styled.div`
width:260px;

 @media screen and (max-width: 934px) {

}

`

const LilaItem = styled.div`

`

const LilaQty = styled.div`

 @media screen and (max-width: 376px) {
 margin-right:20px;
}

display:flex;
height:40px;
align-items:center;
justify-content:space-between;

.lila-icon {
display:flex;
align-items:center;
  cursor: pointer;
  height: 1px;
  position: relative;
  width: 10px;
  &:before,
  &:after{
    background: var(--txt);
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    top: 0;
    width: 10px;
    transition: transform 500ms ease;
  }
  &:after {
    transform-origin: center;
  }
  &.collapsed {
    &:after {
      transform: rotate(90deg);
    }
    &:before {
      transform: rotate(180deg);
    }
  }
}
`


const LilaPlus = styled.div`
 @media screen and (max-width: 376px) {
width:20px;
height:20px;}
width:40px;
height:40px;
border-radius:50%;
display:flex;
justify-content:center;
align-items:center;
`


const LilaMinus = styled.div`
width:40px;
height:40px;
border-radius:50%;
display:flex;
justify-content:center;
align-items:center;
font-family:${fonts.bcExtraLight};

 @media screen and (max-width: 376px) {
 width:20px;
height:20px;
}

`

const LilaIcon = styled.div`
width:100%;
`

const LilaRipples = styled(Ripples)`
width:40px;
height:40px;
border-radius:50%;
cursor:pointer;

 @media screen and (max-width: 376px) {
width:20px;
height:20px;
}
`


const LilaTitle = styled.div`
display:flex;
height:40px;
align-items:center;
font-family:${fonts.bcExtraLight};
`

const Title = styled.div`
font-family:${fonts.bcExtraLight};

`
const SubTitle = styled.div`
margin-left:1rem;
opacity:.5;

 @media screen and (max-width: 364px) {
}
`

const FlexTitle = styled.div`
display:flex;
`

const LilaMax = styled.div`
padding-left:43px;
font-family:${fonts.bcHairLine};
`




class CartThumb extends React.Component {
  constructor(props: any) {
    super(props)
    this.state = {

    }
  }



  componentDidMount() {

  }

  render() {
    return(

      <Fade top>
        <LilaCartThumb >
<LilaWrap>
  <LilaHoverWrap>          <LilaHover></LilaHover>
  </LilaHoverWrap>
          <LilaItemTitle>
            <LilaTitle> <LocalizedLink to={this.props.link}><Title>{this.props.title}</Title></LocalizedLink> <SubTitle>{this.props.subTitle}</SubTitle></LilaTitle>
            <div style={{fontFamily:fonts.bcExtraLight}
            }>   {this.props.price}€</div> </LilaItemTitle>
</LilaWrap>
          <LilaItemCol_2>          <LilaQty>
<FlexTitle>
            <LilaRipples onClick={()=>this.props.minus()}>   <LilaMinus> <LilaIcon className={'lila-icon'}></LilaIcon></LilaMinus></LilaRipples>
            <LilaMinus>{this.props.quantity}</LilaMinus>
            <LilaRipples onClick={()=>this.props.plus()} >   <LilaPlus> <LilaIcon className={'lila-icon collapsed'}></LilaIcon></LilaPlus></LilaRipples>
</FlexTitle>

          </LilaQty>
            {
              this.props.quantity>=10&&
              <LilaMax >max.</LilaMax>

            }
          </LilaItemCol_2>

          <LilaItemCol>

            { this.props.itemID === '001'&&
            <Img>
              <ZkittlezImg2/></Img>
}

            {this.props.itemID === '002'&&
            <Img>
              <ZkittlezImg3/></Img>
}
            {this.props.itemID === '003'&&
            <Img>
              <AhImg2/></Img>
}
            {this.props.itemID === '004'&&
            <Img>
              <AhImg3/></Img>
}
            {this.props.itemID === '005'&&
            <Img>
              <ShImg1/></Img>
}
            {this.props.itemID === '006'&&
            <Img>
              <ShImg1/></Img>
}
            {this.props.itemID === '009'&&
            <Img>
              <MaskImg1/></Img>
}
            <LilaRemove onClick={() => this.props.removeItem()}>

              <div className={'close-icon collapsed'}></div>
            </LilaRemove>

          </LilaItemCol>


        </LilaCartThumb>
      </Fade>


    )
  }
}


export default CartThumb


//<P><Zoom delay={4200} right cascade>CBD BLÜTEN</Zoom></P>
